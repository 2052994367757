
import {defineComponent, ref, onMounted, watch, computed} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { SaveBUsProps, UserProps, RoleProps, BuTreeProps, SelectGlobalProps, BusParamsProps, Bu } from './types'
import { usrFinduserbyorgid, usrFinduserrole, getBuSelected, roleFindrolebytype, getBuTreeList, getBuDealer, usrImportsetting, usrSaveuserorg, usrSaveuserrole, deleteBuSelected, removeUserRole, saveBuAndRegion } from '@/API/user'
import { getPermissions } from "@/utils"
import {
  userFinduserbyid
} from '@/API/user';
export default defineComponent({
  name: "UserSetting",
  setup() {
    const store = useStore()
    const route = useRoute()
    const userDataList = ref<UserProps[]>([])
    // 定义分配角色添加的dialog开关
    const addvisibleRole = ref(false);
    // 定义订阅BUs添加的dialog开关
    const addvisibleBUs = ref(false);

    // if current user is dealerItAdmin
    const dealerItAdmin = ref(false);

    // if edited user is dealerItAdmin
    const editedUserIsDealerItAdmin = ref(false);

    //根据用户身份只读
    const isReadOnly = computed(() => store.state.user.legalEntity.entityCode!== 'Dealer' || (dealerItAdmin.value && editedUserIsDealerItAdmin.value))


    // 定义Assigned Roles 数据
    const rolesData = ref<RoleProps[]>([]);
    const assignedRoleList = ref<RoleProps[]>([]);

    // 定义订阅BUs的数组
    const treeData = ref<BuTreeProps[]>([]);
    const subData = ref<Bu[]>([])
  
    // 定义添加dialog中的value
    const roleValue = ref("");

    // 定义输入框检索的变量
    const userSelect = ref();
    const roleselect = ref();
    const optionsUser = ref<UserProps[]>([]);
    const optionsRole = ref<RoleProps[]>([]);
    const replaceFields = ref();
    const isShowRegion = ref(false)

    // 替换项,中英文菜单
    replaceFields.value = {
      title: store.state.lang === "en" ? "nameEn" : "nameCn",
    };
    watch(
      () => store.state.lang,
      () => {
        replaceFields.value = {
            title: store.state.lang === 'en' ? 'nameEn' : 'nameCn',
        }
    })
    
    const checkedKeys = ref();
  
    const formatAPISelect = (data: Bu[]) => {
        const arr: number[]  = []
        data.forEach(item => {
          if (item.orgid) {
              arr.push(Number(item.orgid))
          }
        })
        console.log(arr, '---------arr');
        return arr
    }

    // 获取初始化数据
    const initUser = () => {
      const params = {
          usrid: route.params.id
      }
      userFinduserbyid({
        params: {
          usrid:  route.params.id
        }
      }).then((res) => {
        editedUserIsDealerItAdmin.value = res.dealerItAdmin;
      });
      // 获取分配role的数据
      usrFinduserrole({ params: params }).then((data)=>{
        assignedRoleList.value = data
      })
      
      // 获取订阅BUs的数据
      getBuSelected({ params: params }).then((data)=>{
        let buValueArr: Bu[] = [];

        // 没有regionDto不显示region列表
        isShowRegion.value = data.some((item) => {
          return item.regionDto && (item.regionDto.length > 0)
        })

        for (const item of data) {
          const buRegionValue: Bu[] = item.buRegionValue;
          
          if (item.buRegionValue && item.buRegionValue.length) {
            item.allBuRegionIdArr = buRegionValue.map(regionItem => {
              return regionItem.id
            })        
          }
          
          buValueArr = buValueArr.concat(item) as Bu[]
        }
        subData.value = buValueArr
      })
    }

    // 删除assigned Roles 
    const removeRole = (usrrolid: string) => {
        const params = {
          usrrolid
        }
        removeUserRole({ params }).then(() => {
            initUser()
        })
    }

    // Assign Roles,数据
    const roleList = () => {
      const params = {
        type: route.params.type,
      };
      roleFindrolebytype({ params: params }).then((data)=>{
        rolesData.value = data
      })
    };

    // Subscribe BUs,数据
    // （受控）展开指定的树节点
    const expandedKeys = ref();
   
    // 给没一组数据添加key
    const addFieldKey = (data: BuTreeProps[]): BuTreeProps[] => {
      data.forEach(item => {
        item['key'] = item.id
        if (item.children && item.children.length > 0) {
          item.children.forEach(child => {
            child['key'] = child.id
          })
        }
      })
      return data
    }

    // 给dealer添加children
    /* const addFieldChildren = (data: BuTreeProps[]): BuTreeProps[] => {
      data.forEach(item => {
        item['children'] = []
        item['key'] = item.id
        if (item.children.length > 0) {
          item.children.forEach(child => {
            child['key'] = child.id
          })
        }
      })
      return data
    } */

    const subScribe = () => {
      /* if (route.params.type === '10001') {
            getBuList().then(data => {
              treeData.value = addFieldKey(data)
            }) 
      } else {
            const params = {
                dealerId: route.params.orgid
            }
            getBuListByDealer({params}).then(data => {
              treeData.value = addFieldChildren(data)
            })
      } */
      if (route.params.usrtpcode === 'Dealer') {
        const params = {
            dealerId: route.params.orgid
        }
        getBuDealer({params}).then(data => {
          treeData.value = addFieldKey(data)
        })
      } else {
        getBuTreeList().then(data => {
          treeData.value = addFieldKey(data)
        })
      } 
    }

    // 查询用户名，获取焦点时
    const findUserData = () => {
      const params = {
        orgid: route.params.orgid,
      };
      // 当获取数据后，再次获取焦点后不获取数据
      if  (userDataList.value.length === 0){
        usrFinduserbyorgid({ params: params }).then((data)=>{
          userDataList.value = data
        })
      }
    }
    let userId: string;
    // 选择之后，
    const findUserChange = (value: string, option: SelectGlobalProps) => {
        userId = option.key
    }
    // 点击import User
    const imoprtUser = () => {
      if (!userId) return false
      const params = {
        from: userId,
        to: route.params.id
      }
      usrImportsetting(params).then(()=>{
        initUser()
      })
    }

// dialog-----------------------------------------------------
    // 点击分配角色的+ 显示dialog
    const handleAddRole = () => {
      addvisibleRole.value = true;
      roleList();
    };

    // 点击订阅BUs的+ 显示dialog

    const handlesubBUs = async() => {
      addvisibleBUs.value = true;
      // 等待获取数据结束
      await subScribe();
      // 设置树，默认选中。
      checkedKeys.value = formatAPISelect(subData.value)
    };

    // 树状图
   
    // 当前选中的树节点
    // 当前点中的节点
    const clickKeys = ref();

     const findParent = (arr: BuTreeProps[], id: string): any => {
        if (!arr) return {}
        for (const obj of arr) {
            if (obj.key === id) {
                return obj ? obj : {}
            }
            const ret = findParent(obj.children, id)
            if (Object.keys(ret).length > 0) return ret
        }
        return {};
    }

    const getParentId = () => {
        const arr: BusParamsProps[] = []
        if (checkedKeys.value.checked.length > 0 && treeData.value.length > 0) {
            for (const key of checkedKeys.value.checked) {
              console.log(findParent(treeData.value, key));
                arr.push(findParent(treeData.value, key))
            }
        }
        return arr
    }
    // 点击订阅BUs的ok
    const handleOkSubBU = () => {
      addvisibleBUs.value = false;
      // 判断是否点击过树状图
      if (Array.isArray(checkedKeys.value)) return false
      let objP: SaveBUsProps;
      const saveBUsParams: SaveBUsProps[] = []
      // subChildren = getParentId()
      const arrConcat: BusParamsProps[] = Array.from(new Set(getParentId()))
      arrConcat.forEach((item) => {
        objP = {
          crtby: '',
          crtbynm: '',
          crted: 0,
          orgid: item.id,
          orgnmcn: item.nameCn,
          orgnmen: item.nameEn,
          orgtp: +item.type,
          orgtpnm: item.typeName,
          state: true,
          usrid: route.params.id as string,
          usrorgid: '',
          orgcode: item.code
        }
        saveBUsParams.push(objP)
      })
      usrSaveuserorg(saveBUsParams).then(() =>{
          addvisibleBUs.value = false
          initUser()
      })
 
    };
    // 取消订阅
    const handleCancelSubBU = () => {
      addvisibleBUs.value = false
    }
    let roleOption: RoleProps
    // 获取当前选中role的信息
    const findRoleChange = (value: string, option: SelectGlobalProps) => {
        rolesData.value.forEach((item: RoleProps) => {
          if (item.rolid === option.key) roleOption = item
        })
    }
    const handleOkAssRole = () => {
          const params = {
            crtby: roleOption.crtby,
            crtbynm: roleOption.crtbynm,
            crted: roleOption.crted,
            rolid: roleOption.rolid,
            state: true,
            usrid: route.params.id,
            usrrolid: "",
          };
          usrSaveuserrole(params).then(() =>{
              addvisibleRole.value = false
              initUser()
          })
    }


    // 取消分配
    const handleCancelRole = () => {
      roleselect.value = ''
      addvisibleRole.value = false
    }
    // 删除bu
    const removeUserOrgEvent = (e: MouseEvent, usrorgid: string) => {
        const params = {
            usrorgId: usrorgid,
            usrId: route.params.id
        }
        deleteBuSelected({params: params}).then(() => {
            initUser()
        })
    }

    const getBuRegion = (idArr: any, regionDto: any) => {
      return regionDto.filter((item: any) => {
        return idArr.indexOf(item.id) > -1 
      })
    }

    // 保存bu和region
    const regionSave = () => {
      let buRegionValueArr: Bu[] = [];
      
      for (const item of subData.value) {
        console.log(item.allBuRegionIdArr)
        if (item.allBuRegionIdArr && item.allBuRegionIdArr.length) {
          const buRegionArr = getBuRegion(item.allBuRegionIdArr, item.regionDto)
          buRegionValueArr = buRegionValueArr.concat(buRegionArr) as Bu[]
        }
      }
      
      const params = {
          usrid: route.params.id,
          selectedRegion: buRegionValueArr
      }

      saveBuAndRegion(params).then(() => {
        message.success('Save successfully');
        initUser()
      })
    }

    onMounted(() => {
      // 获取初始化数据
      initUser();
      userFinduserbyid({
        params: {
          usrid: store.state.user.usrid
        }
      }).then((res) => {
        console.log("store.state.user.usrid " + store.state.user.usrid)
        console.log("current user is dealerIt admin " + res.dealerItAdmin)
        dealerItAdmin.value = res.dealerItAdmin;
      });
    });

    return {
      isReadOnly,
      findUserData,
      replaceFields,
      handleOkAssRole,
      handleOkSubBU,
      handleCancelRole,
      handleCancelSubBU,
      // 添加dialog
      addvisibleRole,
      handleAddRole,
      addvisibleBUs,
      handlesubBUs,
      roleValue,
      // 查找user的检索
      optionsUser,
      userSelect,
      userDataList,
      // 点击importUser
      imoprtUser,
      // 查找role的检索
      assignedRoleList,// 分配role的数据
      optionsRole,
      roleselect,
      removeRole,
      rolesData,
      // dialog树状图
      subData,
      treeData,
      clickKeys,
      checkedKeys,
      expandedKeys,
      removeUserOrgEvent,
      findUserChange,
      findRoleChange,
      getPermissions,
      regionSave,
      isShowRegion,
    };
  },
});
