// 登录用户的数据
export interface CurrentuserProps {
    crtby: string;
    crtbynm: string;
    crted: number;
    email: string;
    fulnm: string;
    legalEntity: {
        entityCode: string;
        entityName: string;
        entityNameEn: string;
        entityType: string;
        id: number;
        parentId: string;
        showEntity: string;
    };
    menulist: TreeProps[];
    orgid: string;
    orgnm: string;
    state: boolean;
    userroles: RoleProps[];
    usrid: string;
    usrnm: string;
    usrtp: number;
    usrtpnm: string;
    key: string;
}
// privilege group表格数据type
export interface PGProps {
    key?: string;
    grpdsc: string;
    grpnmcn: string;
    grpnmen: string;
    grptp: number;
    grptpnm: string;
    privgrpid: string;
    state: boolean;
    crtby: string;
    crtbynm: string;
    crted: number;
}
// 菜单权限表格数据
export interface RoleTableProps {
    crtby: string;
    crtbynm: string;
    crted: number;
    mnuid: string;
    privcd: string;
    privdsc: string;
    privid: string;
    privnmcn: string;
    privnmen: string;
    privtp: number;
    privtpnm: string;
    state: boolean;
}
// 权限的数据
export interface RoleProps {
    key?: string;
    privgrpid: string;
    privgrpnmcn: string;
    privgrpnmen: string;
    rolcd: string;
    roldsc: string;
    rolid: string;
    rolnmcn: string;
    rolnmen: string;
    roltp: number;
    roltpnm: string;
    state: boolean;
    crtby: string;
    crtbynm: string;
    crted: number;
    usrrolid: string;
    roleCode: string;
}
// user的数据type
export interface UserProps {
    key?: string;
    usrid: string;
    usrnm: string;
    fulnm: string;
    email: string;
    usrtp: number;
    usrtpnm: string;
    orgid: string;
    orgnmcn: string;
    orgnmen: string;
    state: boolean;
    crted: number;
    crtby: string;
    crtbynm: string;
    mdfed: number;
    mdfby: string;
    mdfbynm: string;
    entityName: string;
    entityIds: number[];
    dealerItAdmin: boolean;
    showEntity: boolean;
}

// interface UsersProps {
//     crtby: string;
//     crtbynm: string;
//     crted: number;
//     email: string;
//     fulnm: string;
//     orgid: string;
//     orgnm: string;
//     state: boolean;
//     usrid: string;
//     usrnm: string;
//     usrtp: number;
//     usrtpnm: string;
//   }
// dealer的组织架构
export interface OrgDealerProps {
    dealid: string;
    dealnmen: string;
    dealnmcn: string;
    dealcd: string;
    mbafccd: string;
    swtcd: string;
    ndcd: string;
    opendt: string;
    closedt: string;
    parentid: string;
    dealtp: string;
    state: boolean;
}
// 搜索的params
export interface SearchParamsProps {
    type?: string;
    name?: string;
    pageIndex: number;
}
// 存储BU
export interface SaveBUsProps {
    crtby: string;
    crtbynm: string;
    crted?: number;
    orgid: string;
    orgnmcn: string;
    orgnmen: string;
    orgtp: number;
    orgtpnm: string;
    state: true;
    usrid: string;
    usrorgid: string;
    orgcode?: string;
}
// 分页信息的数据type
export interface PageOptionProps {
    pageSize: number;
    current: number;
    total: number;
    pageSizeOptions?: string[];
    showSizeChanger?: boolean;
    showQuickJumper?: boolean;
}
// type选择
export interface SelectProps {
    children?: string[];
    disabled: boolean;
    key: number;
    value: string;
}
export interface ParamsProps {
    content: PGProps[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: {};
    size: number;
    sort: {};
    totalElements: number;
    totalPages: number;
}
// 树里面的表格被选择后
export interface TableCheckedDataProps {
    [key: string]: string[];
}
// PG 三个已选
export interface SelectMenuCheckedProps {
    apilist: [];
    mnulist: SelectMenu[];
    privlist: SelectMenu[];
}
// PG 三个已选
export interface SelectMenuRoleCheckedProps {
    apiList: [];
    mnuList: SelectMenu[];
    privList: SelectMenu[];
}
// role Tree
export interface RoleTreeProps {
    apilist: APIMenuProps[];
    mnulist: TreeProps[];
}
// 左侧树
export interface TreeProps {
    disableCheckbox?: boolean;
    crtby?: string;
    crtbynm?: string;
    crted?: number;
    icon?: string;
    mnuid: string; // id
    key: number;
    mnunmcn: string;
    mnunmen: string;
    mnudsc?: string; // 描述
    mnuseq?: string; // 排序
    privlist: string[]; // 表格数据
    children: TreeProps[];
    mnuuri: string; // 路由
    parentid?: string;
    state?: boolean; // 
}
//  订阅BUs 树状图
export interface BuTreeProps {
    buid: string;
    bunmcn: string;
    bunmen: string;
    butp: number;
    butpnm: string;
    key: string;
    state?: boolean;
    children: BuTreeProps[];
    id: string;
}
// 树选中的
export interface SelectMenu {
    mnuid: string;
    privid?: string;
    privids?: SelectMenu[];
}
// api Menu
export interface APIMenu {
    apiid: string;
}

interface APIListProps {
    key?: string;
    apidsc?: string;
    apigrpnm?: string;
    apiid: string;
    apimhd: string;
    apinm: string;
    apiver?: string;
    state?: number;
    crtby?: string;
    crtbynm?: string;
    crted?: number;
}
export interface APIMenuProps {
    apigrpnm: string;
    apilist: APIListProps[];

}

//  组织架构
export interface OrgProps {
    buid: string;
    bunmen: string;
    bunmcn: string;
    butp: number;
    butpnm: string;
    parentid?: string;
    state?: boolean;
}

// 保存的参数
export interface OrgParamsProps {
    children?: [];
    code?: string;
    id: string;
    key?: string;
    nameCN: string;
    nameEN: string;
    type: string;
    typeName: string;
}

// 保存的参数
export interface BusParamsProps {
    children?: [];
    code?: string;
    id: string;
    key?: string;
    nameCn: string;
    nameEn: string;
    type: string;
    typeName: string;
}

export interface MPProps {
    privid?: string | string[];
    apiid?: string;
    mnuid?: string;
    privgrpid: string | string[];
    state: boolean;
}
export interface RoleMPProps {
    privid?: string | string[];
    apiid?: string;
    mnuid?: string;
    rolid?: string | string[];
    privgrpid?: string;
    state: boolean;
    rolmnuid?: string;
}
export interface ActionProps {
    privid: string | string[];
    mnuid?: string;
    privgrpid: string | string[];
    state: boolean;
}
export interface ActionPropsRole {
    rolid: string | string[];
    privid: string;
    mnuid: string;
    state: boolean;
}

// 权限组下面选择
export interface PrgProps {
    crtby?: string;
    crtbynm?: string;
    crted?: number;
    grpdsc?: string;
    grpnmcn: string;
    grpnmen: string;
    grptp: number;
    grptpnm: string;
    privgrpid: string;
    state?: true;
}


interface SlotProps {
    title?: string;
    customRender?: string;
}
export interface ColumnsProps {
    title?: string;
    dataIndex: string;
    slots?: SlotProps;
}


// OEM, Dealer
export interface TypeProps {
    lkpval: number;
    lkpnm: string;
}
// OEM, Dealer
export const userTypeOptions: TypeProps[] = [
    {
        lkpval: 10001,
        lkpnm: 'OEM'
    },
    {
        lkpval: 10002,
        lkpnm: 'Dealer'
    }
]

export interface LegalEntity {
    entityCode: string;
    entityName: string;
    entityType: string;
    id: number;
    parentId?: number | null;
    entityNameEn: string;
}

export interface Entity {
    entityCode: string;
    entityName: string;
    entityType: string;
    id: number;
    parentId?: number | null;
}

export interface OrganizationProps {
    entityCode: string;
    entityName: string;
    entityType: string;
    id: number | string;
    parentId?: number | null;
    showEntity: boolean;
}

// select选择的通用type
export interface SelectGlobalProps {
    children: [];
    disabled: boolean;
    key: string;
    value: string;
}

// entity select type
export interface EntitySelectProps {
    disabled: boolean;
    children: [];
    key: number;
    value: string;
}

interface DataRefProps {
    dataRef: TreeProps[];
}
// tree点击的通用type
export interface TreeClickProps {
    event: string;
    nativeEvent: MouseEvent;
    node: DataRefProps;
    selected: boolean;
    selectedNodes: [];
}
export interface OutLoginProps {
    code: number;
    data: string;
    message: string;
}

export interface Bu {
    id: number | string;
    nameCn: string;
    nameEn: string;
    regionDto: {
        buId: number;
        id: string;
        [key: string]: any
    }[];
    buRegionValue: Bu[];
    [key: string]: any
}

export enum UserRoleMatrixTab {
    Role,
    User
}

export interface roleMatrixItem {
    firstMenuNameEn: string;
    firstMenuNameCn: string;
    secondMenuNameEn: string;
    secondMenuNameCn: string;
    thirdMenuNameEn: string;
    thirdMenuNameCn: string;
    privNameEn: string;
    privNameCn: string;
    privCode: string;
    legalEntity: string;
    userGroupName: string;
    roleName: string;
    createdAt: string;
    createdBy: string;
}